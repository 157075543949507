import { USER_GET_MESSAGES } from "@common/constants/action-types";
export default function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case USER_GET_MESSAGES:
            {
                var filtered = action.payload.data.filter(function(m) {
                    return (m === null || m === void 0 ? void 0 : m.read) === false && (m === null || m === void 0 ? void 0 : m.priority) !== "normal";
                });
                return {
                    urgent: filtered,
                    all: action.payload.data
                };
            }
        default:
            return state;
    }
}
