export var LOCK_PERIODS = {
    ALWAYS_LOCK: -1,
    SESSION_LOCK: -2
};
// Groups of error codes that require specific actions
// influencing the overall app behavior:
// 200001 = Users: email validation failed.
// 200004 = Users: access denied.
// 200020 = Users: session has expired.
// 200021 = Users: parallel sessions limit exceeded.
// 300004 = Playlists: requesting unauthorized resources
export var ERROR_CODES_THAT_REQUIRE_LOGIN = [
    200001,
    200004,
    200020
];
export var ERROR_SUBCODE_GEO_RESTRICTION = 200031;
export var ERROR_SUBCODE_EXISTING_USER = 200030;
export var ERROR_SUBCODE_PASSWORDS_DONT_MATCH = 200003;
export var ERROR_SUBCODE_WRONG_CURRENT_PASSWORD = 200019;
export var ERROR_SUBCODE_TOO_MANY_USERS = 200021;
export var ERROR_SUBCODE_NO_GDPR_CONSENT = 200033;
export var ERROR_SUBCODE_STREAM_LIMIT = 300004;
// Modal names
export var LIMIT = "LimitModal";
export var VOD_MODAL = "VODModal";
export var FAQ_QUESTION_TYPES = {
    EXPANDED: "Expanded",
    CLOSED: "Closed"
};
export var ACCOUNT_LIST_TYPES = {
    ITEM: "ITEM",
    DIVIDER: "DIVIDER"
};
export var ACCOUNT_LIST_ITEMS = {
    ACCOUNT_MODAL: "ACCOUNT_MODAL",
    SUBSCRIPTIONS: "SUBSCRIPTIONS",
    PROMOTIONS: "PROMOTIONS",
    TV_BOX: "TV_BOX",
    // MESSAGE_MODULE: "MESSAGE_MODULE",
    SETTINGS: "SETTINGS",
    TERMS_AND_CONDITIONS: "TERMS_AND_CONDITIONS",
    PRIVACY_POLICY: "PRIVACY_POLICY",
    LOG_OUT: "LOG_OUT",
    CHANGE_PASSWORD: "CHANGE_PASSWORD"
};
export var CONTEXT_MATCH_TYPES = {
    POINTER: "POINTER",
    VALUE: "VALUE",
    EMPTY: "EMPTY"
};
export var CONTEXT_ON_MATCH_ACTIONS = {
    SHOW: "SHOW",
    HIDE: "HIDE"
};
